<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="90%"
    title="选择班级"
    :center="true"
    :before-close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
                <span class="itemLabel">班级编码:</span>
                <el-input
                        v-model="selectDate.projectCode"
                        placeholder="请输入班级编号"
                        size="small"
                        onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                        clearable
                />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel">班级名称:</span>
                <el-input
                        v-model="selectDate.projectName"
                        placeholder="请输入班级名称"
                        size="small"
                        clearable
                />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-cascader
                        v-model="selectDate.areaId"
                        :options="areatreeList"
                        :props="propsarea"
                        clearable
                        filterable
                        size="small"
                ></el-cascader>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
                <span class="itemLabel">培训类型:</span>
                <tree
                        ref="tree"
                        @eventBtn="childBack"
                        :ruleForm="ruleForm"
                        size="small"
                        @clearParams="clearParams"
                        typeStu
                        :modal="false"

                />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
                <span class="itemLabel">机构名称:</span>
                <el-input
                        v-model="selectDate.compName"
                        placeholder="请输入机构名称"
                        size="small"
                        clearable
                />
            </div>

          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="projectId"
            style="width: 100%"
            :header-cell-style="tableHeader"
            @selection-change="change"
            stripe
          >
            <el-table-column
              type="selection"
              min-width="100px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>

              <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      :index="indexMethod"
                      min-width="100px"
                      fixed
              />
              <el-table-column
                      label="班级编码"
                      align="left"
                      prop="projectCode"
                      show-overflow-tooltip
                      min-width="130px"
                      fixed
              />
              <el-table-column
                      label="班级名称"
                      align="left"
                      show-overflow-tooltip
                      prop="projectName"
                      min-width="130px"
              />
              <el-table-column
                      label="机构名称"
                      align="left"
                      prop="compName"
                      show-overflow-tooltip
                      min-width="130px"
              />
              <el-table-column
                      label="行政区划"
                      align="left"
                      prop="areaNamePath"
                      show-overflow-tooltip
                      min-width="100px"
              />
              <el-table-column
                      label="培训类型"
                      align="left"
                      prop="trainTypeNamePath"
                      show-overflow-tooltip
                      min-width="130px"
              />
              <el-table-column
                      label="班级状态"
                      align="left"
                      prop="projectStudyTimeState"
                      min-width="100px"
                      show-overflow-tooltip>
                  <template slot-scope="scope">
                                <span>{{
                                    $setDictionary(
                                        "PROJECTSTATE",
                                        scope.row.projectStudyTimeState
                                    )
                                    }}</span>
                  </template>
              </el-table-column>
              <el-table-column
                      label="班级有效期"
                      align="left"
                      show-overflow-tooltip
                      min-width="130px"
              >
                  <template slot-scope="scope">
                      <span>{{ scope.row.startDate | momentDate }} ~ {{scope.row.endDate | momentDate}}</span>
                  </template>
              </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>选择班级</p>
            <p>（{{ multipleTable.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div
                v-for="(item, index) in multipleTable"
                :key="index"
                class="BatchforFlex"
              >
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img
                    :src="require('@/assets/close_.png')"
                    alt=""
                    @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)"
                  />
                </div>
                <span style="padding-left:5px">{{
                  item.projectName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="dialog-footer"
        style="margin-top:10px;display:flex;justify-content: center;"
      >
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
      tree,
    Empty,
    PageNum,
    // treePopupQuestion,
  },
  mixins: [List],
  data() {
    return {
        selectDate: {
            projectName: "", //班级名称
            projectCode: "", //班级编码
            compName: "", //开班单位
            areaId: "", //行政区域

            creationTime: "", //创建时间
            startTime: "", //开始时间
            endTime: "", //结束时间
            isSubsidy: "", //班级补贴
        },
        areatreeList: [],
        // classtatusList: [],
        propsarea: {
            value: "value",
            label: "label",
            emitPath: false,
            checkStrictly: true,
        },
        ruleForm: {
            Trainingtype: "",
        },
        params: {},

        dialogVisible: false,
        start: false,
        multipleTable: [],
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {
    this.getareatree();
  },
  updated() {

  },
  methods: {
    // 培训类型
    childBack(params) {
        this.params = { ...params };
    },
    // 培训类型
    clearParams() {
        this.params = {};
    },
    //获取行政区划
    getareatree() {
        this.$post("/sys/area/tree").then((ret) => {
            this.areatreeList = ret.data;
        });
    },
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    tinit() {
      this.init();
    },
    change(rowArr) {
      console.log(rowArr);
      // this.backArr = rowArr;
      this.multipleTable = rowArr;
    },
    async showPopUp(list) {
      this.dialogVisible = true;
      this.start = true;
      if (this.start) {
        await this.getData();

        list.forEach((e,i)=>{
            this.$refs.multipleTable.toggleRowSelection(e, true);
        })
      }
    },
    async getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
          projectName: this.selectDate.projectName,
          projectCode: this.selectDate.projectCode,
          compName: this.selectDate.compName,
          areaId: this.selectDate.areaId,
      };
      if (this.params.trainFormId) {
          params.trainTypeId = this.params.trainFormId;
      }
      if (this.start) {
        await this.doFetch({
          url: "/biz/project/businessListPage",
          params,
          pageNum,
        });
      }
    },
    deleteImg(itemobj) {
      this.multipleTable.find((el) => {
        if (el.projectId == itemobj.projectId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { multipleTable } = this;
      if (multipleTable.length) {
        this.$emit("eventBus", multipleTable);
        this.dialogVisible = false;
        this.multipleTable = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要配置的班级",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.multipleTable = [];
      this.questionBankName = ''
      this.categoryCode = ''
      this.$refs.multipleTable.clearSelection();
    },
    doClose() {
      this.dialogVisible = false;
      this.multipleTable = [];
      this.$refs.multipleTable.clearSelection();
       this.questionBankName = ''
      this.categoryCode = ''
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-table th.el-table__cell{
  padding: 8px 0!important;
}
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
