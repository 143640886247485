<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">广告内容配置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stuJson.stu == "add" ? "新增" : "修改" }}广告</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="bannerList">
              <div class="FormBox">
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7.5rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="主题名称：" prop="themeName">
                      <el-input
                        v-model="baseform.themeName"
                        size="small"
                        maxlength="4"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="图片："
                      prop="advertsLogo"
                      :class="[rotationId ? 'item-disabled' : '']"
                      required
                    >
                        <el-upload
                          :on-change="handleAvatarSuccess2"
                          :before-upload="$beforeAvatarUpload"
                          :http-request="$requestMine"
                          :show-file-list="false"
                          class="img-el-upload"
                          action
                          accept="image/png, image/gif, image/jpg, image/jpeg"
                        >
                            <el-image
                                    :src="
                          baseform.advertsLogoUrl ||
                          require('@/assets/develop.png')
                        "
                                    fit="contain"
                                    class="imgCenter"
                            ></el-image>
                        </el-upload>
<!--                        <p>注意:各端banner尺寸建议(宽*高)</p>-->
<!--                        <p style="color: #f46173">建议尺寸750*240</p>-->
                    </el-form-item>

                    <el-form-item label="终端类型：" prop="terminalType">
                      <el-radio-group
                        v-model="baseform.terminalType"
                        @change="changeTerminalType"
                      >
                        <el-radio :label="10">安知App</el-radio>
                        <el-radio :label="20">PC学习端</el-radio>
                        <el-radio :label="30">安知乐业小程序</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="显示位置：" prop="positionType">
                        <el-radio-group
                                v-model="baseform.positionType"
                        >
                            <el-radio :label="10" v-if="baseform.terminalType!='30'">安知培训播放页面</el-radio>
                            <el-radio :label="20" v-if="baseform.terminalType!='20'">精品课程详情页面</el-radio>
                            <el-radio :label="30" v-if="baseform.terminalType!='30'">首页</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="点击功能：" prop="clickType">
                        <el-radio-group
                                v-model="baseform.clickType"
                        >
                            <el-radio :label="10">打开链接</el-radio>
                            <el-radio :label="20" v-if="baseform.terminalType == '10'">打开应用</el-radio>
                            <el-radio :label="30" v-if="baseform.terminalType != '20'">打开小程序</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="链接网址" prop="clickContent1" v-if="baseform.clickType == '10'">
                        <el-input
                          v-model="baseform.clickContent1"
                          size="small"
                          maxlength="100"
                          show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应用功能" prop="clickContent2" v-if="baseform.clickType == '20'">
                        <el-select
                                v-model="baseform.clickContent2"
                                placeholder="请选择应用"
                                size="small"
                                clearable
                        >
                            <el-option
                                    v-for="item in innerList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小程序地址" prop="clickContent3" v-if="baseform.clickType == '30'">
                        <el-input
                          v-model="baseform.clickContent3"
                          size="small"
                          maxlength="100"
                          show-word-limit
                        ></el-input>
                    </el-form-item>


                    <el-form-item label="是否指定班级：" prop="appointProjectStatus" v-if="baseform.positionType == '10'">
                        <el-radio-group
                          v-model="baseform.appointProjectStatus"
                        >
                          <el-radio :label="true">是</el-radio>
                          <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="选择班级：" prop="terminal" class="labelTop" v-if="baseform.appointProjectStatus&&baseform.positionType == '10'">
                        <el-button size="mini" type="primary" class="bgc-bv" @click="configAdInfoSelectClass()"
                        >增加班级</el-button>
<!--                        搜索项：班级编码、班级名称、行政区划、培训类型、机构名称-->
<!--                        列表项：班级编码、班级名称、行政区划、培训类型、机构名称、班级状态、班级有效期-->
                        <el-table
                                ref="multipleTable"
                                :data="classList"
                                size="small"
                                tooltip-effect="dark"
                                style="width: 100%"
                                :header-cell-style="tableHeader"
                                v-if="classList.length"
                        >
                            <el-table-column
                                    label="序号"
                                    align="center"
                                    type="index"
                                    index="index"
                                    min-width="100px"
                                    fixed/>
                            <el-table-column
                                    label="班级编码"
                                    align="left"
                                    prop="projectCode"
                                    show-overflow-tooltip
                                    min-width="200px"
                                    fixed
                            />
                            <el-table-column
                                    label="班级名称"
                                    align="left"
                                    show-overflow-tooltip
                                    prop="projectName"
                                    width="200"
                            />
                            <el-table-column
                                    label="机构名称"
                                    align="left"
                                    prop="compName"
                                    show-overflow-tooltip
                                    width="200"
                            />
                            <el-table-column
                                    label="行政区划"
                                    align="left"
                                    prop="areaNamePath"
                                    show-overflow-tooltip
                                    width="100"
                            />
                            <el-table-column
                                    label="培训类型"
                                    align="left"
                                    prop="trainTypeNamePath"
                                    show-overflow-tooltip
                                    width="150"
                            />
                            <el-table-column
                                    label="班级状态"
                                    align="left"
                                    prop="projectStudyTimeState"
                                    show-overflow-tooltip>
                              <template slot-scope="scope">
                                <span>{{
                                    $setDictionary(
                                        "PROJECTSTATE",
                                        scope.row.projectStudyTimeState
                                    )
                                    }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                                    label="班级有效期"
                                    align="left"
                                    prop="endDate"
                                    show-overflow-tooltip
                                    min-width="150"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.startDate | momentDate }} ~ {{scope.row.endDate | momentDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                                    width="100px"
                                    fixed="right"
                            >
                                <div slot-scope="scope">
                                    <el-button
                                            style="padding: 0 5px"
                                            type="text"
                                            size="mini"
                                            @click="deleteRow(scope.$index)"
                                    >移除</el-button
                                    >
                                </div>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv"
                  >取 消</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="doEditSave"
                  >确 定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <adInfoSelectClass
              ref="adInfoSelectClass"
              @eventBus="adInfoSelectClassBack"
      ></adInfoSelectClass>
  </div>
</template>
<script>

import List from "@/mixins/List";
import adInfoSelectClass from "@/views/workOrder/adInfoPop/adInfoSelectClass";
export default {
  name: "adInfoUpdate",
  components: {
      adInfoSelectClass,
  },
  mixins: [List],
  data() {
    //验证码校验
    let validateUrl = (rule, value, callback) => {
      if (this.baseform.rotationType == 1) {
        let reg1 =/([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|[wW]{3}.|[wW][aA][pP].|[fF][tT][pP].|[fF][iI][lL][eE].)[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/
//          let reg =
//           /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
//          let strRegex = "^((https|http|ftp|rtsp|mms)?://)" + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?"          
//  + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
//   + "|" // 允许IP和DOMAIN（域名）
//   + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
//   + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
//   + "[a-z]{2,6})" // first level domain- .com or .museum
//   + "(:[0-9]{1,4})?" // 端口- :80
//   + "((/?)|" // a slash isn't required if there is no file name
//   + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
//    var re = new RegExp(strRegex);
        if (value && !reg1.test(value) ) {
        // if (value && !reg.test(value) && !re.test(value)) {
          callback(new Error("请输入带有http或https的正确链接地址"));
        } else {
          callback();
        }
      } else if (this.baseform.rotationType == 0) {
        if (value == "") {
          callback(new Error("请选择应用"));
        } else {
          callback();
        }
      } else if (this.baseform.rotationType == 2) {
        if (value == "") {
          callback(new Error("请输入跳转小程序链接地址"));
        } else {
          callback();
        }
      }
    };
    return {
      advertsId: "",

      stuJson: {
        stu: this.$route.query.stu,
      },

      classList:[],//班级列表

      innerList: [],
      // 基本信息
      baseform: {
          themeName: "", // 轮播图名称
          advertsLogoUrl: "", //图片地址URL
          advertsLogo: "", //图片地址KEY
          fileKey: "", //上传的图片
          terminalType: "", //终端类型
          positionType: "", //显示位置
          clickType: "", //点击功能
          clickContent: "", //链接网址/应用功能/小程序地址
          appointProjectStatus: true, //是否指定班级
      },

      rules: {
        themeName: [
          { required: true, trigger: "blur", message: "请输入主题名称" },
        ],
        advertsLogo: [
            { required: true, trigger: "change", message: "请上传图片" },
        ],
        terminalType: [
            { required: true, trigger: "change", message: "请选择终端类型" },
        ],
        positionType: [
            { required: true, trigger: "change", message: "请选择显示位置" },
        ],
        clickType: [
            { required: true, trigger: "change", message: "请选择点击功能" },
        ],
        clickContent1: [
            { required: true, trigger: "blur", message: "请输入链接网址" },
        ],
        clickContent2: [
            { required: true, trigger: "blur", message: "请输入应用功能" },
        ],
        clickContent3: [
            { required: true, trigger: "blur", message: "请输入小程序地址" },
        ],
        appointProjectStatus: [
            { required: true, trigger: "change", message: "请选择是否指定班级" },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.advertsId = this.$route.query.id;
      this.getDeil();
    }
    // this.getareatree();
    this.getDictionary();
  },
  mounted() {},
  computed: {},
  methods: {
    // 点击终端类型
    changeTerminalType(e){
        if(e == 10){
            // 安知app
            this.baseform.positionType = 10
            this.baseform.clickType = 20
        }else if(e == 20){
            // pc学习端
            this.baseform.positionType = 10
            this.baseform.clickType = 10
        }else if(e == 30){
            // 安知乐业小程序
            this.baseform.positionType = 20
            this.baseform.clickType = 30
        }

    },
    // 配置题库
    configAdInfoSelectClass() {
        this.$refs.adInfoSelectClass.showPopUp(this.classList);
    },
    // 批量选课件
    adInfoSelectClassBack(list) {
        console.log('list',list)
        this.classList = list
    },

    rotationTypeChange(val) {
      this.baseform.rotationPath = "";
      this.$refs["baseform"].clearValidate("rotationPath");
    },
    // 应用
    getDictionary() {
      const APP_INNER_PATH_list = this.$setDictionary("APP_INNER_PATH", "list");
      const list = [];
      for (const key in APP_INNER_PATH_list) {
        list.push({
          value: key,
          label: APP_INNER_PATH_list[key],
        });
      }
      this.innerList = list;
    },
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.baseform.advertsLogoUrl = result.data.fileURL || "";
          that.baseform.advertsLogo = result.data.fileKey || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 删除行
    deleteRow(index){
        console.log(index)
        this.classList.splice(index,1)
        console.log(this.classList)
    },
    //点击编辑获取数据
    getDeil() {
      this.$post("/biz/terminalAdverts/info", {
          advertsId: this.$route.query.id,
      })
        .then((res) => {
          if (res.status == "0") {
            const baseform = res.data || {};
            this.baseform = {
              ...baseform
            };
            if(res.data.clickType == 10){
                this.$set(this.baseform,'clickContent1',res.data.clickContent)
            }
            if(res.data.clickType == 20){
                this.$set(this.baseform,'clickContent2',res.data.clickContent)
            }
            if(res.data.clickType == 30){
                this.$set(this.baseform,'clickContent3',res.data.clickContent)
            }
            if(res.data.appointProjectStatus){
                this.$set(this,'classList', res.data.advertsAppointProjectBOS)
            }

          }
        })
        .catch(() => {});
    },
    // 编辑保存
    doEditSave() {
      const params = {
        themeName: this.baseform.themeName, //主题 名称
        advertsLogo: this.baseform.advertsLogo, //图片
        terminalType: this.baseform.terminalType, //终端类型
        positionType: this.baseform.positionType, //显示位置
        clickType: this.baseform.clickType, //点击功能
        clickContent:
            this.baseform.clickType == '10'?this.baseform.clickContent1:
            this.baseform.clickType == '20'?this.baseform.clickContent2:
            this.baseform.clickType == '30'?this.baseform.clickContent3:'', //链接网址/应用功能/小程序地址
        appointProjectStatus: this.baseform.appointProjectStatus, //是否指定班级
      };
      if (this.advertsId) {
        params.advertsId = this.advertsId;
      }
      console.log(this.classList.map(item=>{
          return item.projectId;
      }))
      if(this.baseform.positionType == '10'&&this.baseform.appointProjectStatus&&this.classList.length == 0){
          this.$message.error('请至少添加一个班级')
          return
      }else if(this.baseform.positionType == '10'&&this.baseform.appointProjectStatus&&this.classList.length > 0){
          params.appointProjectIdList = this.classList.map(item=>{
              return item.projectId;
          })
      }else{
          params.appointProjectIdList = []
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          this.$post(
            this.advertsId ? "/biz/terminalAdverts/modify" : "/biz/terminalAdverts/insert",
            params
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.doCancel();
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 点击取消
    doCancel() {
      this.$router.push({
        path: "/web/workOrder/adInfoConfig",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .FormBox {
    flex: 1;
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
.labelTop{
  /deep/.el-form-item__label{
    width: 7.5rem !important;
  }
  /deep/.el-form-item__content{
    margin-left: 0!important;
  }
}
/deep/.el-table th.el-table__cell{
  padding: 0;
}
</style>

